import PropTypes from 'prop-types';

export const filters = {
  UNREAD: 1,
  ALL: 2,
};
export const filterValues = Object.values(filters);

export const notificationPropType = PropTypes.shape({
  description: PropTypes.string,
  read: PropTypes.bool.isRequired,
  actor: PropTypes.string,
  actor_url: PropTypes.string,
  verb: PropTypes.string,
  obj: PropTypes.string,
  obj_url: PropTypes.string,
  target: PropTypes.string,
  target_url: PropTypes.string,
  data: PropTypes.object,
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  nf_type: PropTypes.string,
});

export const pageInfoPropType = PropTypes.shape({
  hasPrevPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  numPages: PropTypes.number.isRequired,
});
