import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { RelativeDate } from 'components';
import { Tags } from 'helpers/dictionaries';
import { notificationPropType } from './helpers';

export const notificationText = (notification, onClick) => {
  const n = notification;

  const linkPiece = (key, id) => {
    const text = n[key];
    let url = n[`${key}_url`];
    if (key === 'obj' || key === 'target') {
      url = `${window.URLS['notifications:read_and_redirect'](id)}?next=${n[`${key}_url`]}`;
    }
    return url ? (
      <a href={url} key={key} onClick={onClick}>
        {text}
      </a>
    ) : (
      text
    );
  };

  const pieces = [
    n.actor && linkPiece('actor'),
    n.verb,
    n.obj && linkPiece('obj', n.id),
    n.obj && n.target && 'in',
    n.target && linkPiece('target', n.id),
  ].filter((piece) => !!piece);

  const lastPieceIndex = pieces.length - 1;

  return (
    <span>
      {pieces.map((piece, index) => {
        if (index !== lastPieceIndex) {
          return <span key={index}>{piece} </span>;
        }
        return piece;
      })}
    </span>
  );
};

export const notificationIcon = (notification) => {
  if (notification && notification.data) {
    if (notification.data.type === Tags.EVENT) {
      return 'fa-calendar';
    }
    if (notification.data.type === Tags.CAROUSEL) {
      return 'fa-sliders';
    }
  }
  return 'fa-bullhorn';
};

export default class Notification extends React.PureComponent {
  static propTypes = {
    notification: notificationPropType,
    markRead: PropTypes.func.isRequired,
  };

  handleRead = (event) => {
    event.preventDefault();
    this.props.markRead(this.props.notification);
  };

  render() {
    const { notification } = this.props;
    const text = notificationText(notification);
    let date = moment(notification.created);
    let relativeDate = <RelativeDate date={date} />;

    const className = classNames('notification', {
      'notification--read': notification.read,
    });

    return (
      <div className={className}>
        <div className="notification__row">
          <div className="notification__icon">
            <span className={`fa ${notificationIcon(notification)} fa-fw`} />
          </div>
          <div className="notification__text">{text}</div>
          <div className="notification__date hidden-xs">{relativeDate}</div>
          <div className="notification__action">
            {!notification.read && (
              <button
                type="button"
                className="notification__action-buton btn btn-default btn-xs"
                onClick={this.handleRead}
              >
                <span className="fa fa-check fa-fw" />
              </button>
            )}
          </div>
        </div>
        <div className="notification__row notification__row--bottom visible-xs">
          <div className="notification__date">{relativeDate}</div>
        </div>
      </div>
    );
  }
}
