import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  requestNotifications,
  readNotification,
  readAllNotifications,
} from 'store/actions/notifications';
import { PageHeader } from 'components';
import { failedAssertion } from 'helpers/reporting';

import { filters, notificationPropType, pageInfoPropType } from './helpers';
import NotificationsContent from './NotificationsContent';

export const filterName = (filter) => {
  if (filter === filters.UNREAD) {
    return 'Unread';
  } else if (filter === filters.ALL) {
    return 'All';
  } else {
    failedAssertion('Invalid filter type', { filter });
    return '';
  }
};

export class Notifications extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(notificationPropType).isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    requestNotifications: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired,
    readAllNotifications: PropTypes.func.isRequired,
    pageInfo: pageInfoPropType.isRequired,
  };

  state = {
    filter: filters.UNREAD,
    currentPage: 1,
  };

  componentDidMount() {
    this.requestNotifications(this.state.currentPage);
  }

  componentDidUpdate() {
    if (!this.props.isLoading && !this.props.hasErrored) {
      if (!this.props.data.some((notification) => !notification.read)) {
        $('.notification-status.notification-indicator').remove();
      }
    }
  }

  requestNotifications = (pageNum) => {
    this.setState({ currentPage: pageNum }, () => {
      this.props.requestNotifications({
        filter: filterName(this.state.filter),
        page: pageNum,
      });
    });
  };

  handleReadAll = (event) => {
    event.preventDefault();
    this.props.readAllNotifications();
  };

  handleViewAll = (event) => {
    event.preventDefault();
    this.setState({ filter: filters.ALL, currentPage: 1 }, () =>
      this.requestNotifications(this.state.currentPage)
    );
  };

  handleViewUnread = (event) => {
    event.preventDefault();
    this.setState({ filter: filters.UNREAD, currentPage: 1 }, () =>
      this.requestNotifications(this.state.currentPage)
    );
  };

  handleReload = (event) => {
    event.preventDefault();
    this.requestNotifications(this.state.currentPage);
  };

  render() {
    const notifications = this.props.data;
    const hasUnread = notifications.some((x) => !x.read);

    return (
      <div className="white-soft-box2 white-soft-box2--no-animate">
        <PageHeader.Wrapper>
          <PageHeader.Title>
            {filterName(this.state.filter)} Notifications
            {this.state.filter === filters.ALL && (
              <button
                type="button"
                className="btn btn-default notifications__title-button"
                onClick={this.handleViewUnread}
              >
                View Unread
              </button>
            )}
          </PageHeader.Title>
          <PageHeader.Actions>
            {hasUnread && (
              <button
                className="btn btn-default pull-right mark-all-notifications"
                onClick={this.handleReadAll}
              >
                Mark all as read
              </button>
            )}
          </PageHeader.Actions>
        </PageHeader.Wrapper>
        <NotificationsContent
          notifications={notifications}
          isLoading={this.props.isLoading}
          hasErrored={this.props.hasErrored}
          filter={this.state.filter}
          handleReload={this.handleReload}
          handleViewAll={this.handleViewAll}
          handleViewUnread={this.handleViewUnread}
          markRead={this.props.readNotification}
          pageInfo={this.props.pageInfo}
          currentPage={this.state.currentPage}
          fetchPage={this.requestNotifications}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.notifications.data,
    pageInfo: state.notifications.pageInfo,
    hasErrored: state.notifications.hasErrored,
    isLoading: state.notifications.isLoading,
  };
};

export default connect(
  mapStateToProps,
  {
    requestNotifications,
    readNotification,
    readAllNotifications,
  }
)(Notifications);
