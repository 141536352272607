import React from 'react';
import PropTypes from 'prop-types';
import { pageInfoPropType } from './helpers';

export const Pagination = ({ pageInfo, currentPage, fetchPage }) => {
  const pages = generatePages({ pageInfo, currentPage, fetchPage });
  if (pageInfo.numPages <= 1) {
    return null;
  }
  return (
    <nav aria-label="Notifications pages">
      <ul className="pagination">{pages}</ul>
    </nav>
  );
};

Pagination.propTypes = {
  pageInfo: pageInfoPropType.isRequired,
  currentPage: PropTypes.number.isRequired,
  fetchPage: PropTypes.func.isRequired,
};

function generatePages({ pageInfo, currentPage, fetchPage }) {
  const { hasNextPage, hasPrevPage, numPages } = pageInfo;
  const pages = [];

  pages.push(
    <li className={!hasPrevPage ? 'disabled' : ''} key="previous">
      {hasPrevPage ? (
        <a aria-label="Previous" onClick={() => fetchPage(currentPage - 1)}>
          <i aria-hidden="true" className="fa fa-angle-double-left" />
        </a>
      ) : (
        <span>
          <i aria-hidden="true" className="fa fa-angle-double-left" />
        </span>
      )}
    </li>
  );

  let maxPage;
  if (currentPage >= 1 && currentPage <= 4) {
    if (numPages >= 8) {
      maxPage = 8;
    } else {
      maxPage = numPages;
    }
  } else {
    if (currentPage + 4 <= numPages) {
      maxPage = currentPage + 4;
    } else {
      maxPage = numPages;
    }
  }
  let startPage;
  if (currentPage >= 1 && currentPage <= 4) {
    startPage = 1;
  } else {
    startPage = currentPage - 3;
  }
  for (let pageNum = startPage; pageNum <= maxPage; pageNum++) {
    pages.push(
      <li className={pageNum === currentPage ? 'active' : ''} key={`page-${pageNum}`}>
        <a aria-label={`Page ${pageNum}`} onClick={() => fetchPage(pageNum)}>
          {pageNum}
        </a>
        {pageNum === currentPage && <span className="sr-only">(current)</span>}
      </li>
    );
  }

  if (numPages > 5 && currentPage + 4 < numPages) {
    pages.push(
      <li className="disabled" key="pagination-space">
        <span aria-label="Pagination Gap">
          <i aria-hidden="true" className="fa fa-ellipsis-h" />
        </span>
      </li>
    );
    pages.push(
      <li key={`page-${numPages}`}>
        <a aria-label={`Page ${numPages}`} onClick={() => fetchPage(numPages)}>
          {numPages}
        </a>
      </li>
    );
  }

  pages.push(
    <li className={!hasNextPage ? 'disabled' : ''} key="next">
      {hasNextPage ? (
        <a aria-label="Next" onClick={() => fetchPage(currentPage + 1)}>
          <i aria-hidden="true" className="fa fa-angle-double-right" />
        </a>
      ) : (
        <span>
          <i aria-hidden="true" className="fa fa-angle-double-right" />
        </span>
      )}
    </li>
  );

  return pages;
}
