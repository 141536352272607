import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'components';
import Notification from './Notification';
import { Pagination } from './Pagination';
import { failedAssertion } from 'helpers/reporting';
import { filters, filterValues, notificationPropType, pageInfoPropType } from './helpers';

export default class NotificationsContent extends React.PureComponent {
  static propTypes = {
    notifications: PropTypes.arrayOf(notificationPropType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    filter: PropTypes.oneOf(filterValues).isRequired,
    handleReload: PropTypes.func.isRequired,
    handleViewAll: PropTypes.func.isRequired,
    handleViewUnread: PropTypes.func.isRequired,
    markRead: PropTypes.func.isRequired,
    pageInfo: pageInfoPropType.isRequired,
    fetchPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
  };

  render() {
    const {
      isLoading,
      hasErrored,
      notifications,
      filter,
      handleViewAll,
      handleViewUnread,
      markRead,
    } = this.props;
    const filteredNotifications = notifications.filter((x) => filter === filters.ALL || !x.read);

    if (isLoading && !notifications.length) {
      return <Loading />;
    }

    if (hasErrored) {
      failedAssertion('Notifications render error');
      return (
        <div className="page-empty page-empty--error">
          <div className="page-empty__icon">
            <span className="fa fa-exclamation-triangle" />
          </div>
          <div className="page-empty__title">Something went wrong</div>
          <div className="page-empty__subtitle">Please try again later.</div>
          <button
            type="button"
            className="page-empty__button btn btn-default"
            onClick={this.props.handleReload}
          >
            Reload
          </button>
        </div>
      );
    }

    if (filteredNotifications.length === 0) {
      return (
        <div className="page-empty">
          <div className="page-empty__icon">
            <span className="fa fa-bell-o" />
          </div>
          <div className="page-empty__title">All clear!</div>
          <div className="page-empty__subtitle">
            {filter === filters.UNREAD ? 'No unread notifications' : 'No notifications'}
          </div>
          {filter === filters.UNREAD && (
            <button
              type="button"
              className="page-empty__button btn btn-default"
              onClick={handleViewAll}
            >
              View all notifications
            </button>
          )}
        </div>
      );
    }

    const sortedNotifications = filteredNotifications.sort((a, b) => {
      // Always put unread notifications above read
      if (a.read && !b.read) {
        return 1;
      } else if (!a.read && b.read) {
        return -1;
      }
      // Sort all others by date
      return new Date(b.created) - new Date(a.created);
    });

    return (
      <div className="notifications">
        {sortedNotifications.map((notification) => (
          <Notification key={notification.id} notification={notification} markRead={markRead} />
        ))}
        {filter === filters.UNREAD && (
          <button
            type="button"
            className="btn btn-default notifications__expand-button"
            onClick={handleViewAll}
          >
            <span className="fa fa-clock-o icon-space-r" />
            View all notifications
          </button>
        )}
        {filter === filters.ALL && (
          <button
            type="button"
            className="btn btn-default notifications__expand-button"
            onClick={handleViewUnread}
          >
            <span className="fa fa-bell-o icon-space-r" />
            View unread notifications only
          </button>
        )}
        <Pagination
          pageInfo={this.props.pageInfo}
          currentPage={this.props.currentPage}
          fetchPage={this.props.fetchPage}
        />
      </div>
    );
  }
}
